import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Musician } from "../hooks";
import Typography from "./Typography";
import Button from "../components/Button";
import Image from "../components/Image";

function MusicianCard({ id, name, votes, imageUrl }: Musician) {
  const navigate = useNavigate();
  const goVote = useCallback(() => {
    navigate(`/vote/${id}`);
  }, [id, navigate]);

  return (
    <div className="flex items-baseline justify-center md:max-w-[250px]">
      <div className="bg-gray-dark">
        <Image
          src={imageUrl}
          fallbackSrc="/singer_placeholder.png"
          alt={name}
          className="bg-cover bg-center md:max-h-[250px]"
        />

        <div className="grid grid-cols-1 gap-1 px-2 py-2 text-center md:gap-4 md:px-4 md:py-4">
          <Typography type="h5">{name}</Typography>
          <Typography type="h4" className="text-green">
            {votes} 票
          </Typography>

          <Button disabled={true} onClick={goVote}>
            我要投票
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MusicianCard;
