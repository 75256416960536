import { Outlet } from "react-router-dom";
import Logo from "../components/Logo";
import Typography from "../components/Typography";
import Nav from "../components/Nav";
import OpenLink from "../components/OpenLink";
import { Toaster } from "react-hot-toast";

function DefaultLayout() {
  return (
    <>
      <header className="fixed z-20 flex h-[80px] w-full items-center justify-between bg-transparent px-[24px] backdrop-blur-sm md:px-[56px] lg:px-[72px]">
        <Logo />
        <Nav />
      </header>

      <main className="px-[24px] pt-[80px] md:px-[56px] xl:px-[240px] 2xl:px-[320px]">
        <Toaster position="bottom-center" />
        <Outlet />
      </main>

      <footer className="pb-12 pt-[200px] md:pb-6">
        <div className="flex flex-col flex-wrap items-center justify-center md:flex-row md:items-start md:justify-evenly">
          <Logo className="mb-6 h-[40px] w-[170px] md:h-[80px] md:w-[340px]" />

          <OpenLink url="http://www.goldenmelody.net/" className="mb-6">
            <Typography type="body-sm">金旋獎官網</Typography>
          </OpenLink>

          <div className="mb-6">
            <OpenLink url="https://www.qubic.app/">
              <Typography className="mb-6 md:mb-4" type="body-sm">
                關於 Qubic
              </Typography>
            </OpenLink>
            <OpenLink url="mailto:hello@qubic.app">
              <Typography type="body-sm">聯絡我們</Typography>
            </OpenLink>
          </div>

          <div className="flex items-center">
            <OpenLink
              url="https://www.facebook.com/QubicWallet"
              className="icon mr-6"
            >
              <img src="fill_facebook.png" alt="fb" />
            </OpenLink>
            <OpenLink
              url="https://www.instagram.com/qubic_nft/"
              className="icon"
            >
              <img src="fill_instagram.png" alt="ig" />
            </OpenLink>
          </div>
        </div>

        <div className="mt-8 text-center md:mt-16">
          <OpenLink url="https://www.amis.com/">
            <Typography type="caps">
              © 2022 AMIS Technologies Co., Ltd
            </Typography>
          </OpenLink>
        </div>
      </footer>
    </>
  );
}

export default DefaultLayout;
