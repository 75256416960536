import { useCallback, useEffect, useRef, useState } from "react";

const getRemainTime = (deltaMs: number) => {
  if (deltaMs <= 0) {
    return [0, 0, 0, 0];
  }
  const s = Math.floor((deltaMs / 1000) % 60);
  const m = Math.floor((deltaMs / 60000) % 60);
  const h = Math.floor((deltaMs / 3600000) % 24);
  const d = Math.floor(deltaMs / 86400000);
  return [d, h, m, s];
};

const INTERVAL_MS = 1000;
type IntervalType = ReturnType<typeof setInterval>;

const useCountdown = (targetTime: number = 0) => {
  const [remainingTime, setRemainingTime] = useState([0, 0, 0, 0]);
  const [isPast, setPast] = useState(targetTime - Date.now() <= 0);
  const intervalRef = useRef<IntervalType>();

  const calculate = useCallback(() => {
    const deltaMs = targetTime - Date.now();
    const remainTime = getRemainTime(deltaMs);
    setPast(deltaMs <= 0);
    setRemainingTime(remainTime);
  }, [targetTime]);

  useEffect(() => {
    calculate();
    if (!isPast) {
      intervalRef.current = setInterval(calculate, INTERVAL_MS);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [calculate, isPast]);

  return {
    remainingTime,
    isPast,
  };
};

export default useCountdown;
