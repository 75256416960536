import React from "react";
import Typography from "./Typography";

function Heading({ id, children }: { id?: string; children: React.ReactNode }) {
  return (
    <div
      id={id}
      className="mt-[48px] mb-[24px] flex items-center whitespace-nowrap md:mt-[84px] md:mb-[24px]"
    >
      <Typography type="h2" className="text-orange">
        {children}
      </Typography>
      <div className="ml-[16px] h-[1px] w-full border-t border-orange sm:ml-[32px]"></div>
    </div>
  );
}

export default Heading;
