import clsx from "clsx";
import { Musician } from "../hooks";
import Typography from "./Typography";
import Image from "./Image";

function Mask({ rank }: { rank: number }) {
  if (rank === 1)
    return (
      <svg width={0} height={0}>
        <defs>
          <clipPath id="mask1">
            <path
              id="mask_1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M117.355 0.0406385C146.872 0.802618 173.848 14.523 195.088 35.1653C216.757 56.2238 234.326 83.0844 233.995 113.398C233.668 143.459 214.409 168.561 193.4 189.932C172.246 211.451 147.216 228.421 117.355 232.049C83.2378 236.194 44.4842 235.645 20.4267 210.948C-3.51849 186.367 -1.10672 147.7 1.77262 113.398C4.35469 82.6381 13.561 52.5906 35.5281 31.0462C57.2268 9.76518 87.0667 -0.741238 117.355 0.0406385Z"
              fill="black"
            />
          </clipPath>
        </defs>
      </svg>
    );

  if (rank === 2)
    return (
      <svg width={0} height={0}>
        <defs>
          <clipPath id="mask2">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M125.214 0.102077C159.197 1.17405 193.97 11.7663 215.728 37.7124C236.127 62.0378 235.656 96.1397 232.135 127.602C229.064 155.036 217.409 180.126 197.618 199.507C177.982 218.737 152.646 229.506 125.214 232.313C94.006 235.506 60.157 236.114 36.1746 216.027C10.7363 194.721 1.52568 160.624 0.196656 127.602C-1.19548 93.0124 4.44217 56.616 28.7606 31.8145C53.3228 6.76455 90.0242 -1.00796 125.214 0.102077Z"
              fill="black"
            />
          </clipPath>
        </defs>
      </svg>
    );

  if (rank === 3)
    return (
      <svg width={0} height={0}>
        <defs>
          <clipPath id="mask3">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M127.928 1.01243C158.278 4.59557 180.549 27.1918 199.764 51.5504C219.219 76.213 236.636 103.48 233.669 135.057C230.179 172.191 216.055 212.009 183.183 227.981C150.46 243.88 114.244 225.04 81.6164 208.935C48.9727 192.823 13.7155 175.092 3.68966 139.388C-6.85884 101.823 6.0361 60.4838 31.8553 31.7268C55.7614 5.10044 92.8682 -3.12684 127.928 1.01243Z"
              fill="black"
            />
          </clipPath>
        </defs>
      </svg>
    );

  return <></>;
}

type TopVotingSectionProps = {
  musicians: Musician[];
};
function TopVotingSection({ musicians }: TopVotingSectionProps) {
  return (
    <div
      className={clsx(
        "grid grid-cols-1 gap-12",
        musicians.length === 1 && "md:grid-cols-1",
        musicians.length === 2 && "md:grid-cols-2",
        musicians.length === 3 && "md:grid-cols-3"
      )}
    >
      {musicians.map((m, idx) => {
        const rank = idx + 1;
        const rankImg = `top${rank}.png`;
        const maskId = `#mask${rank}`;
        const { imageUrl, id, name, votes } = m;

        return (
          <div key={id} className="flex items-center justify-center">
            <div className="relative">
              <Mask rank={rank} />
              <img
                src={rankImg}
                alt={rankImg}
                className="absolute -top-6 -left-8 z-10 h-[48px] w-[130px]"
              />
              <Image
                fallbackSrc="/singer_placeholder.png"
                src={imageUrl}
                alt={name}
                className="h-[234px] min-w-[234px] bg-cover bg-center"
                style={{ clipPath: `url(${maskId})` }}
              />
              <Typography type="h4" className="mt-[22px] text-center">
                {name}
              </Typography>
              <Typography type="h1" className="mt-[8px] text-center text-green">
                {votes} 票
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TopVotingSection;
