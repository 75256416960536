import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import clsx from "clsx";

const Modal = ({
  children,
  containerClassName,
}: {
  children: React.ReactNode;
  containerClassName?: string;
}) => {
  const timerId = useRef<number | null>(null);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    timerId.current = window.setTimeout(() => {
      navigate("/");
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (timerId.current !== null) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="fixed inset-0 z-20 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#000000B3]" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                "relative mx-auto w-full max-w-xs overflow-scroll",
                containerClassName
              )}
            >
              {children}
              <div className="absolute top-4 right-4">
                <button
                  className="rounded-full bg-[#F1F1F1] p-4 transition-all hover:bg-[#D8D8D8]"
                  onClick={onClose}
                >
                  <AiOutlineClose className="h-4 w-4 text-[#000000]" />
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
