import React, { useCallback } from "react";

type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  fallbackSrc: string;
};

function Image({ onError, fallbackSrc, alt, ...props }: ImageProps) {
  const handleOnError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      let target = e.target as any;
      target.src = fallbackSrc;
    },
    [fallbackSrc]
  );
  return <img {...props} alt={alt} onError={onError || handleOnError} />;
}

export default Image;
