import { useEffect, useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { ConnectorUpdate, ConnectorEvent } from "@web3-react/types";
import { Web3Provider } from "@ethersproject/providers";
import { utils as ethersUtils } from "ethers";
import { QubicConnector } from "@qubic-js/react";

import {
  REACT_APP_QUBIC_API_KEY,
  REACT_APP_QUBIC_API_SECRET,
  REACT_APP_QUBIC_CHAIN_ID,
  REACT_APP_INFURA_TOKEN,
} from "../constants/private";
import { setToStorage, StorageKey } from "../utils/storage";

const qubicConnector = new QubicConnector({
  apiKey: REACT_APP_QUBIC_API_KEY,
  apiSecret: REACT_APP_QUBIC_API_SECRET,
  chainId: Number.isNaN(Number(REACT_APP_QUBIC_CHAIN_ID))
    ? 1
    : Number(REACT_APP_QUBIC_CHAIN_ID),
  infuraProjectId: REACT_APP_INFURA_TOKEN,
  autoHideWelcome: true,
  enableIframe: true,
});

const handleUpdate = (event: ConnectorUpdate) => {
  const { account, chainId } = event;
  if (account && chainId) {
    setToStorage(StorageKey.QUBIC_WALLET, {
      account,
      chainId,
      timestamp: Date.now(),
    });
  }
};

const useQubicWallet = () => {
  const context = useWeb3React<Web3Provider>();
  const { account, library: ethersProvider, activate, deactivate } = context;

  useEffect(() => {
    qubicConnector.on(ConnectorEvent.Update, handleUpdate);
    return () => {
      qubicConnector.off(ConnectorEvent.Update, handleUpdate);
    };
  }, []);

  const connect = useCallback(async () => {
    await activate(qubicConnector, (e: Error): void => {
      console.error("Qubic wallet activate error", e);
    });
  }, [activate]);

  const disconnect = useCallback(() => {
    deactivate();
  }, [deactivate]);

  const personalSign = useCallback(
    async (data: any) => {
      if (!ethersProvider) {
        return "";
      }
      const dataString = JSON.stringify(data);
      const hexString = ethersUtils.hexlify(
        ethersUtils.toUtf8Bytes(dataString)
      );
      const payload = {
        jsonrpc: "2.0",
        method: "personal_sign",
        params: [hexString, account],
      };
      try {
        const signature = (await ethersProvider.provider.request?.(
          payload
        )) as string;
        return signature;
      } catch (error) {
        console.error("Personal Sign Error");
        return "";
      }
    },
    [account, ethersProvider]
  );

  useEffect(() => {
    // Auto connect in qubic wallet dapp browser
    if (window.ethereum?.isQubic) connect();
  }, [connect]);

  const output = useMemo(
    () => ({
      isLogin: account && account.length > 0 ? true : false,
      account,
      provider: ethersProvider?.provider,
      connect,
      disconnect,
      personalSign,
    }),
    [account, ethersProvider?.provider, connect, disconnect, personalSign]
  );

  return output;
};

export default useQubicWallet;
