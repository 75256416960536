import clsx from "clsx";
import React from "react";
import Typography from "./Typography";

function Button({
  contained,
  children,
  disabled,
  className,
  onClick,
}: {
  contained?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <button
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      className={clsx(
        "py-2 transition-all disabled:cursor-not-allowed disabled:opacity-30 md:py-3",
        contained
          ? "bg-green-dark text-white hover:bg-green-dark-hover disabled:hover:bg-green-dark"
          : "bg-green-light text-green-dark hover:bg-green-hover disabled:hover:bg-green-light",
        className
      )}
    >
      <Typography type="button">{children}</Typography>
    </button>
  );
}

export default Button;
