import React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import DefaultLayout from "./layouts/DefaultLayout";

export default React.memo(() => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/vote/:id" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
      </Route>
    </Routes>
  );
});
