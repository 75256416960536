import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";

import {
  useCountdown,
  useExecuteVote,
  useShortlist,
  useShortlistItem,
  useTopVoting,
} from "../hooks";
import Typography from "../components/Typography";
import Heading from "../components/Heading";
import AboutUsItem from "../components/AboutUsItem";
import MusicianCard from "../components/MusicianCard";
import Modal from "../components/Modal";
import TopVotingList from "../components/TopVotingList";
import VoteCard from "../components/VoteCard";

function Hero() {
  return (
    <>
      <div className="hero_mobile md:hidden"></div>
      <div className="hero_desktop hidden md:block"></div>
    </>
  );
}

function Countdown() {
  const { remainingTime, isPast } = useCountdown(
    new Date(2022, 5, 4, 17, 0, 0).getTime()
  );
  const [d, h, m, s] = remainingTime;

  if (isPast)
    return (
      <Typography type="h1" className="border-2 p-4 text-center">
        投票已截止
      </Typography>
    );

  return (
    <div className="mb-[88px] flex items-center justify-evenly">
      <div className="flex flex-col items-center justify-center">
        <Typography type="counting" className="mb-1 md:mb-2">
          {d}
        </Typography>
        <Typography type="body-lg">天</Typography>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Typography type="counting" className="mb-1 md:mb-2">
          {h}
        </Typography>
        <Typography type="body-lg">時</Typography>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Typography type="counting" className="mb-1 md:mb-2">
          {m}
        </Typography>
        <Typography type="body-lg">分</Typography>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Typography type="counting" className="mb-1 md:mb-2">
          {s}
        </Typography>
        <Typography type="body-lg">秒</Typography>
      </div>
    </div>
  );
}

function IWannaVote() {
  return (
    <div className="grid grid-cols-1 gap-[32px] md:gap-[64px]">
      <div>
        <Heading>我要投票</Heading>
        <Typography type="body-lg" className="text-center md:text-left">
          參加投票，幫助喜歡的音樂人獲得發行 NFT 的機會！
          <br />
          最高票三位選手將會發行其原創音樂作品 NFT
        </Typography>
      </div>

      <div className="flex flex-col flex-wrap items-center justify-center gap-[32px] md:flex-row md:justify-between">
        <div className="mb-[8px] grid grid-cols-1 gap-[8px] md:flex-1">
          <Typography type="body-lg" className="text-center md:text-left">
            更可免費獲得
          </Typography>
          <Typography type="h1" className="text-center text-green md:text-left">
            39th 政大金旋獎紀念 NFT
          </Typography>
        </div>
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="/異象之海.jpg"
          src="/異象之海.mov"
          className="h-[228px] w-[228px] shadow-theme md:h-[266px] md:w-[266px]"
        />
      </div>

      <div className="flex flex-col flex-wrap items-center justify-center gap-[32px] md:flex-row-reverse md:justify-between">
        <div className="mb-[8px] grid grid-cols-1 gap-[8px] md:flex-1">
          <Typography type="body-lg" className="text-center md:text-left">
            若你最支持、所投票數最多的選手成為前三名！便可獲得
          </Typography>
          <Typography type="h1" className="text-center text-green md:text-left">
            支持選手原創 NFT
          </Typography>
        </div>
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="/異象之海.jpg"
          src="/原創音樂NFT示意影片.mp4"
          className="h-[228px] w-[228px] shadow-theme md:h-[266px] md:w-[266px]"
        />
      </div>
    </div>
  );
}

function VoteDesc() {
  return (
    <>
      <Heading id="vote">投票辦法</Heading>
      <ul className="list-disc pl-[24px]">
        <li>
          <Typography type="body-lg">
            投票期間：5/28 - 6/4 17:00 截止，每天可投下 3 票。
          </Typography>
        </li>
        <li>
          <Typography type="body-lg">
            限擁有 Qubic 加密錢包帳戶朋友參加，點擊「投票」將引導完成註冊。
          </Typography>
        </li>
      </ul>
    </>
  );
}

function TopVoting() {
  const { elapsedMinutes, musicians } = useTopVoting();
  return (
    <>
      <Heading>目前戰況</Heading>

      <Typography type="caps" className="mb-8 text-right md:mb-12">
        於 {elapsedMinutes} 分鐘前更新票數
      </Typography>

      <TopVotingList musicians={musicians} />
    </>
  );
}

function Shortlist() {
  const [search, setSearch] = useState("");
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );
  const [searchValue] = useDebounce(search, 1000);
  const { musicians, elapsedMinutes } = useShortlist(searchValue);

  return (
    <>
      <Heading id="shortlist">決賽入圍音樂人</Heading>

      <div className="mb-8 flex flex-col md:mb-16 md:flex-row md:items-center md:justify-between">
        {/* <Typography type="body-lg" className="mb-4 md:mb-0">
          依選手名稱筆畫或英文字母排序
        </Typography> */}
        <input
          placeholder="搜尋入圍音樂人"
          type="text"
          className="input text-[14px] md:text-[20px]"
          value={search}
          onChange={handleSearchChange}
        />
      </div>

      <Typography type="caps" className="mb-4 text-right md:mb-6">
        於 {elapsedMinutes} 分鐘前更新票數
      </Typography>

      <div className="grid grid-cols-2 gap-3 md:gap-6 lg:grid-cols-3">
        {musicians.map((musician) => (
          <MusicianCard key={musician.id} {...musician} />
        ))}
      </div>
    </>
  );
}

function AboutUs() {
  return (
    <>
      <Heading>關於我們</Heading>
      <div className="grid grid-cols-1 gap-[96px] md:gap-[132px]">
        <AboutUsItem
          title="台灣最大的加密貨幣交易集團"
          subtitle="成立於 2013 年，MaiCoin 集團為台灣交易量最大，且唯一具備區塊鏈技術公司之數位資產領導品牌。"
          learnMoreLink="https://www.maicoin.com/"
          imageClassName="bg-[url('/public/maicoin.png')]"
        />
        <AboutUsItem
          title="最簡單上手的虛擬貨幣錢包"
          alertnate
          subtitle="Qubic 讓您能在多個平台上透過信用卡輕鬆購買數位收藏品，不需要任何虛擬貨幣！"
          learnMoreLink="https://www.qubic.app/"
          imageClassName="bg-[url('/public/qubic.png')]"
        />
        <AboutUsItem
          title="台灣交易量最大加密貨幣交易所"
          subtitle="2018 年上線，為台灣首家數位資產兌換法幣、數位資產轉換數位資產，更為全球第一間將用戶資金交由銀行信託保管，真正落實資金信託安全保管的交易所。 "
          learnMoreLink="https://max.maicoin.com/"
          imageClassName="bg-[url('/public/maicoin_max.png')]"
        />
      </div>
    </>
  );
}

function Notice() {
  return (
    <>
      <Heading>注意事項</Heading>

      <ul className="list-disc pl-[24px]">
        {[
          "投票日期為：5/28 開始 - 6/04 下午 17:00 截止，本票選活動每個錢包每日至多可投3票。",
          "參與投票者，必須確實完成 Qubic 虛擬貨幣錢包註冊，得幾登記領取金旋獎紀念 NFT，完成投票即等同於完成登記。 ",
          "每天可以投三票，有參與投票者，均可獲得限量金旋獎紀念 NFT；你可以將票投給你支持的不同選手，當結算時你最支持（獲得你最多票）的選手總票數若為前三名，成為人氣得主，除金旋獎紀念 NFT外，你將額外獲得其原創 NFT 的空投。",
          "如參賽者或投票者，以惡意電腦程式及其他明顯違反活動公平性之方式(如創立假帳號)，意圖混淆或影響比賽結果，皆為違反規定之行為。經由主辦單位發現或網友檢舉後確認屬實，主辦單位可將票數予以刪除或取消參賽/中獎資格。",
          "本活動因故無法進行或更改投票獎項時，主辦單位保留對活動網站、活動規則、獎項、抽獎時間及得獎公佈等的修改權利，修改後不另行通知。",
          "每位投票得獎者，不重複得獎，MaiCoin / AMIS 員工及活動承辦相關單位不具本活動抽獎資格。",
          "參加者須同意接受 AMIS 隱私權保護政策；參加者同意 AMIS 於辦理本活動得獎聯絡事宜、活動分析或其他活動網頁上所聲明之方式及特定目的範圍內，得蒐集、處理及利用參加者所填寫之個人資料；參加者並同意 AMIS 得委託協力廠商負責執行前述各項事務，該廠商得於必要範圍內使用參加者之個人資料；除本活動辦法另有規定外， AMIS 關於參加者個人資料之使用及參加者之權益悉依 AMIS 隱私權保護政策之規範。",
          "金旋獎紀念 NFT 與 人氣獎原創音樂 NFT 發送時間，統一於金旋獎 / AMIS 官方社群進行公布。",
          "得獎者請依規定完成 Qubic 虛擬貨幣錢包註冊與登入，以利 NFT 贈送，逾期兌獎恕不補發贈品。",
        ].map((item, idx) => (
          <li key={idx}>
            <Typography type="body-sm">{item}</Typography>
          </li>
        ))}
      </ul>
    </>
  );
}

function Vote({ id }: { id: string }) {
  const navigate = useNavigate();

  const { musician } = useShortlistItem(id);
  const { isVoting, executeVote } = useExecuteVote(musician?.id);

  const handleExecuteVote = useCallback(async () => {
    const result = await executeVote();
    if (result.msg === "") {
      return;
    }

    if (result.isSuccess) {
      toast.success(result.msg);

      if (result.msg === "投票成功") {
        const timerId = setTimeout(() => {
          navigate("/");
          clearTimeout(timerId);
        }, 300);
      }
    } else {
      toast.error(result.msg);
    }
  }, [executeVote, navigate]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (musician === undefined) {
        navigate("/");
      }
    }, 5000);

    return () => {
      clearTimeout(timerId);
    };
  }, [musician, navigate]);

  if (musician === undefined) {
    return <VoteCard name="-" imageUrl="" votes={0} disabled={true} />;
  }

  return (
    <VoteCard
      {...musician}
      loading={isVoting}
      executeVote={handleExecuteVote}
    />
  );
}

function HomePage() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <Hero />

      <Countdown />

      <IWannaVote />

      <VoteDesc />

      <TopVoting />

      <Shortlist />

      <AboutUs />

      <Notice />

      {id && (
        <Modal containerClassName="min-w-[272px] md:min-w-[360px]">
          <Vote id={id} />
        </Modal>
      )}
    </>
  );
}

export default HomePage;
