type StorageData = Record<string, string | number | boolean>;

export enum StorageKey {
  QUBIC_WALLET = 'QUBIC_WALLET',
  AMIS_DEV_MODE = 'AMIS_DEV_MODE',
}

export const getFromStorage = (key: StorageKey, errorClean: boolean = true): StorageData | null => {
  if (navigator.cookieEnabled) {
    try {
      const raw = localStorage.getItem(key);
      if (raw) {
        return JSON.parse(raw);
      }
    } catch {
      if (errorClean) {
        localStorage.removeItem(key);
      }
      console.error('Failed to get data from localStorage.');
    }
  }
  return null;
};

export const setToStorage = (key: StorageKey, value: StorageData): boolean => {
  if (navigator.cookieEnabled) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch {
      console.error('Failed to set data into localStorage');
    }
  }
  return false;
};
