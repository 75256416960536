import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios, { AxiosError } from "axios";

import { useMusicians } from "../contexts/store";
import useQubicWallet from "./useQubicWallet";
import { shuffle } from "../utils/array";

export { default as useFireStoreDocument } from "./useFireStoreDocument";
export { default as useFireCollection } from "./useFireCollection";
export { default as useFireDocument } from "./useFireDocument";
export { default as useQubicWallet } from "./useQubicWallet";
export { default as useCountdown } from "./useCountdown";

export type Musician = {
  id: string;
  name: string;
  votes: number;
  imageUrl: string;
};
export const useTopVoting = () => {
  const { elapsedMinutes, musicians } = useMusicians();
  let topThree = musicians
    .filter((m) => m.votes > 0)
    .sort((a, b) => b.votes - a.votes)
    .slice(0, 3);

  if (topThree.length < 3) {
    const fills = (
      Array.from({ length: 3 - topThree.length }) as Musician[]
    ).map((item, idx) => {
      return {
        id: idx.toString(),
        name: "-",
        votes: 0,
        imageUrl: "",
      };
    });

    topThree = [...topThree, ...fills];
  }

  return {
    elapsedMinutes,
    musicians: topThree,
  };
};
export const useShortlist = (searchVal: string = "") => {
  const { elapsedMinutes, musicians } = useMusicians();

  const randomNums = useMemo(
    () => [...Array(musicians.length)].map(Math.random),
    [musicians.length]
  );
  const shuffledMusicians = useMemo(
    () => shuffle(musicians, randomNums),
    [musicians, randomNums]
  );

  const sVal = searchVal.trim().toLowerCase();

  const filteredMusicians =
    sVal === ""
      ? shuffledMusicians
      : shuffledMusicians.filter((m) => {
          const name = m.name.toLowerCase();
          return name.includes(sVal);
        });

  return {
    elapsedMinutes,
    musicians: filteredMusicians,
  };
};
export const useShortlistItem = (id: string) => {
  const { elapsedMinutes, musicians } = useShortlist();
  const musician = musicians.find((m) => m.id === id);
  return {
    elapsedMinutes,
    musician,
  };
};
export const useExecuteVote = (musicianId: string | undefined) => {
  const {
    isLogin,
    connect,
    account: tempAccount,
    personalSign: tempPersonalSign,
  } = useQubicWallet();

  const [isVoting, setIsVoting] = useState(false);

  const accountRef = useRef<string | null | undefined>(tempAccount);
  const personalSignRef =
    useRef<(data: any) => Promise<string>>(tempPersonalSign);

  useEffect(() => {
    accountRef.current = tempAccount;
  }, [tempAccount]);

  useEffect(() => {
    personalSignRef.current = tempPersonalSign;
  }, [tempPersonalSign]);

  const executeVote = useCallback(async () => {
    try {
      if (musicianId === undefined) {
        return { isSuccess: false, msg: "投票失敗" };
      }

      setIsVoting(true);

      if (!isLogin) {
        await connect();
        // sleep 1s
        await new Promise((resolve) =>
          setTimeout(() => resolve(undefined), 1000)
        );
        // return { isSuccess: true, msg: "成功連結錢包" };
      }

      // must use the updated account and function to sign message
      const data = {
        address: accountRef.current,
        vote: musicianId,
        nonce: Date.now(),
      };
      const sig = await personalSignRef.current(data);

      if (sig === "") {
        // note: user cancels signing, sig will be empty
        return { isSuccess: true, msg: "" };
      }

      const verification = { data, signature: sig };

      await axios.post("/.netlify/functions/verification", verification);

      return { isSuccess: true, msg: "投票成功" };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { data } = error.response || {};
        if (data.code === 4010) {
          return {
            isSuccess: false,
            msg: "您已達到今日投票上限，請明天再回來投票",
          };
        }
      }
      return { isSuccess: false, msg: "請稍後再試" };
    } finally {
      setIsVoting(false);
    }
  }, [musicianId, isLogin, connect]);

  return { isVoting, executeVote };
};

const useScrollTo = (id: "vote" | "shortlist") => {
  const execScrollTo = useCallback(() => {
    const yOffset = -80;
    const element = document.getElementById(id)!;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }, [id]);

  return (e: React.MouseEvent<HTMLElement>) => {
    execScrollTo();
  };
};
export const useScrollToVote = () => useScrollTo("vote");
export const useScrollToShortlist = () => useScrollTo("shortlist");
