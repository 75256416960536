import clsx from "clsx";
import LearnMoreButton from "./LearnMore";
import Typography from "./Typography";

type AboutUsItemProps = {
  title: string;
  subtitle: string;
  alertnate?: boolean;
  learnMoreLink: string;
  imageClassName: string;
};

function AboutUsItem({
  title,
  subtitle,
  alertnate,
  learnMoreLink,
  imageClassName,
}: AboutUsItemProps) {
  return (
    <div
      className={clsx(
        "flex flex-col items-center md:flex-row md:justify-around",
        alertnate && "md:flex-row-reverse"
      )}
    >
      <div
        className={clsx(
          "mb-[16px] h-[232px] min-w-[260px] bg-cover bg-center",
          imageClassName,
          alertnate ? "md:ml-[24px]" : "md:mr-[24px]"
        )}
      ></div>

      <div className="flex flex-col">
        <Typography type="h3" className="mb-[8px] text-green">
          {title}
        </Typography>

        <Typography type="body-lg" className="mb-[24px] md:mb-[32px]">
          {subtitle}
        </Typography>

        <LearnMoreButton
          className="mt-[24px] self-end md:mt-[40px]"
          learnMoreLink={learnMoreLink}
        />
      </div>
    </div>
  );
}

export default AboutUsItem;
