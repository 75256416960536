import React, { useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { initializeApp } from "firebase/app";
import { collection, doc, getFirestore } from "firebase/firestore";

import { Musician, useFireCollection } from "../hooks";
import {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} from "../constants/private";
import {
  PROJECT_DOC,
  SINGERS_COLLECTION,
  VOTES_SUMMARY_COLLECTION,
} from '../constants/variables';

// Initialize Firebase
const app = initializeApp({
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
});

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

interface Store {
  elapsedMinutes: number;
  musicians: Musician[];
}

const StoreContext = createContext<Store>({
  elapsedMinutes: 1,
  musicians: [],
});

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const goldenMelody = doc(db, PROJECT_DOC);
  const singers = useFireCollection(collection(goldenMelody, SINGERS_COLLECTION), []);
  const votesSummary = useFireCollection(collection(goldenMelody, VOTES_SUMMARY_COLLECTION), []);

  const musicians = useMemo(() => {
    const _singers = (singers as Musician[]) || [];
    const _voteCountsMap = votesSummary.reduce((result, value) => {
      result[value.id] = value.count;
      return result;
    }, {}) as { [key: string]: number };

    return _singers.map((item) => ({
      ...item,
      votes: _voteCountsMap[item.id] || 0,
    }));
  }, [singers, votesSummary]);

  const [elapsedMinutes, setElapsedMinutes] = useState(1);
  useEffect(() => {
    const base = Date.now();

    const intervalId = setInterval(() => {
      const _minutes = Math.floor((Date.now() - base) / 1000 / 60);
      setElapsedMinutes(_minutes >= 1 ? _minutes : 1);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [musicians]);

  return (
    <StoreContext.Provider value={{ musicians, elapsedMinutes }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useMusicians = () =>
  useContextSelector(StoreContext, (m) => ({
    elapsedMinutes: m.elapsedMinutes,
    musicians: m.musicians,
  }));
