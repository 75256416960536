import React from "react";
import clsx from "clsx";

type TypographyProps = {
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "body-lg"
    | "body-sm"
    | "button"
    | "button-sm"
    | "caps"
    | "link"
    | "counting";
  className?: string;
  children: React.ReactNode;
};

const getClassNameByType = (type: TypographyProps["type"]) => {
  switch (type) {
    case "h1":
      return "text-[24px] leading-[32px] font-bold tracking-[8px] md:text-[36px] md:leading-[48px]";
    case "h2":
      return "text-[20px] leading-[28px] font-bold tracking-[24px] md:text-[24px] md:leading-[48px]";
    case "h3":
      return "text-[20px] leading-[28px] font-bold tracking-[8px] md:text-[24px] md:leading-[32px]";
    case "h4":
      return "text-[20px] leading-[28px] font-bold md:text-[24px] md:leading-[32px]";
    case "h5":
      return "text-[16px] leading-[24px] font-bold md:text-[20px] md:leading-[28px]";
    case "body-lg":
      return "text-[14px] leading-[24px] md:text-[20px] md:leading-[32px]";
    case "body-sm":
      return "text-[12px] leading-[24px] md:text-[16px] md:leading-[48px]";
    case "button":
      return "text-[16px] leading-[24px] font-bold tracking-[4px] md:text-[20px] md:leading-[28px]";
    case "button-sm":
      return "text-[12px] leading-[16px] tracking-[4px] font-medium md:text-[16px] md:leading-[24px]";
    case "caps":
      return "text-[12px] leading-[16px] font-light md:text-[16px] md:leading-[24px]";
    case "link":
      return "text-[12px] leading-[16px] tracking-[4px] font-medium md:text-[16px] md:leading-[24px]";
    case "counting":
      return "text-[32px] leading-[32px] font-bold md:text-[64px] md:leading-[64px]";
    default:
      return "";
  }
};

function Typography({ type, children, className }: TypographyProps) {
  const classNameByType = getClassNameByType(type);
  return (
    <div className={clsx("transition-all", classNameByType, className)}>
      {children}
    </div>
  );
}

export default Typography;
