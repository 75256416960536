import clsx from "clsx";
import { AiOutlineLoading } from "react-icons/ai";

import Button from "./Button";
import Image from "./Image";
import Typography from "./Typography";

type VoteCardProps = {
  imageUrl: string;
  name: string;
  votes: number;
  disabled?: boolean;
  loading?: boolean;
  executeVote?: () => Promise<void>;
};
function VoteCard({
  imageUrl,
  name,
  votes,
  disabled = false,
  loading = false,
  executeVote,
}: VoteCardProps) {
  return (
    <div className="bg-gray-dark">
      <Image
        fallbackSrc="/singer_placeholder.png"
        src={imageUrl}
        alt={name}
        className={clsx(
          "min-h-[272px] min-w-[272px] md:min-h-[360px] md:min-w-[360px]",
          "bg-cover bg-center"
        )}
      />

      <div className="grid grid-cols-1 p-6 text-center md:p-8">
        <Typography className="mb-2" type="h5">
          {name}
        </Typography>
        <Typography type="h4" className="text-green">
          {votes} 票
        </Typography>

        <Button
          className="mt-4 md:mt-6"
          contained
          disabled={true || disabled || loading}
          onClick={executeVote}
        >
          <div className="mx-auto flex items-center justify-center">
            確認投票
            <AiOutlineLoading
              className={clsx(
                "ml-2 animate-spin",
                loading ? "block" : "hidden"
              )}
            />
          </div>
        </Button>
      </div>
    </div>
  );
}

export default VoteCard;
