import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useCallback } from "react";
import { AiOutlineMenu, AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import Typography from "./Typography";
import {
  useQubicWallet,
  useScrollToShortlist,
  useScrollToVote,
} from "../hooks";

function NavMenu({
  scrollToVote,
  scrollToShortlist,
  isLogin,
  connectWallet,
  disconnectWallet,
}: {
  scrollToShortlist: (e: React.MouseEvent<HTMLElement>) => void;
  scrollToVote: (e: React.MouseEvent<HTMLElement>) => void;
  isLogin: boolean;
  connectWallet: () => void;
  disconnectWallet: () => void;
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button>
              {open ? <AiOutlineClose /> : <AiOutlineMenu />}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={scrollToVote}
                      className={`${
                        active ? "bg-green-dark text-white" : "text-dark"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      投票辦法
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={scrollToShortlist}
                      className={`${
                        active ? "bg-green-dark text-white" : "text-dark"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      入圍名單
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={isLogin ? disconnectWallet : connectWallet}
                      className={`${
                        active ? "bg-green-dark text-white" : "text-dark"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {isLogin ? "登出" : "連結錢包"}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

function Nav() {
  const scrollToVote = useScrollToVote();
  const scrollToShortlist = useScrollToShortlist();
  const { isLogin, connect, disconnect } = useQubicWallet();
  const connectAndToast = useCallback(async () => {
    await connect();
    toast.success("成功連結錢包");
  }, [connect]);

  return (
    <>
      <div className="hidden md:flex md:items-center md:justify-end">
        <Link to="#vote" onClick={scrollToVote}>
          <Typography type="link" className="hover:opacity-70">
            投票辦法
          </Typography>
        </Link>

        <Link to="#shortlist" onClick={scrollToShortlist}>
          <Typography type="link" className="mx-[48px] hover:opacity-70">
            入圍名單
          </Typography>
        </Link>

        <button
          onClick={isLogin ? disconnect : connectAndToast}
          className="flex items-center border px-4 py-2 transition-all hover:opacity-70"
        >
          <Typography type="button-sm" className="mr-[12px]">
            {isLogin ? "登出" : "連結錢包"}
          </Typography>
          <AiOutlineRight />
        </button>
      </div>

      <div className="md:hidden">
        <NavMenu
          scrollToVote={scrollToVote}
          scrollToShortlist={scrollToShortlist}
          isLogin={isLogin}
          connectWallet={connectAndToast}
          disconnectWallet={disconnect}
        />
      </div>
    </>
  );
}

export default Nav;
