import clsx from "clsx";
function Logo({ className }: { className?: string }) {
  return (
    <img
      className={clsx("h-[32px] w-[136px] md:h-[60px] md:w-[255px]", className)}
      src="/logo.png"
      alt="logo"
    />
  );
}

export default Logo;
