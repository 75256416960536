import { useEffect, useState } from "react";
import {
  onSnapshot,
  DocumentData,
  Query,
} from "firebase/firestore";

export default function useFirestoreCollection<T>(
  query: Query<T>,
  deps: React.DependencyList,
): DocumentData[] {
  const [data, setData] = useState<DocumentData[]>([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(query, (collectionSnap) => {
      setData(collectionSnap.docs.map(snap => ({ id: snap.id, ...snap.data() })));
    });

    return () => {
      unsubscribe();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return data;
}
