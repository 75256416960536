import clsx from "clsx";
import React from "react";

function OpenLink({
  url,
  className,
  children,
}: {
  url: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <a
      href={url}
      className={clsx("link", className)}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

export default OpenLink;
