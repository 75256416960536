export const {
  // Qubic Wallet
  REACT_APP_QUBIC_API_KEY = "",
  REACT_APP_QUBIC_API_SECRET = "",
  REACT_APP_QUBIC_CHAIN_ID = 1,
  REACT_APP_INFURA_TOKEN = "",
  // Firebase
  REACT_APP_FIREBASE_API_KEY = "",
  REACT_APP_FIREBASE_AUTH_DOMAIN = "",
  REACT_APP_FIREBASE_PROJECT_ID = "",
  REACT_APP_FIREBASE_STORAGE_BUCKET = "",
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID = "",
  REACT_APP_FIREBASE_APP_ID = "",
  REACT_APP_FIREBASE_MEASUREMENT_ID = "",
  REACT_APP_FIREBASE_STORE_PATH = "data/goldenMelody",
  REACT_APP_FIREBASE_VOTES_PATH = "data/votingCount",
} = process.env;
