import clsx from "clsx";
import { AiOutlineRight } from "react-icons/ai";

import Typography from "./Typography";

function LearnMoreButton({
  learnMoreLink,
  className,
}: {
  learnMoreLink: string;
  className?: string;
}) {
  return (
    <a
      href={learnMoreLink}
      target="_blank"
      rel="noreferrer"
      className={clsx(
        "flex items-center border px-4 py-2 transition-all hover:bg-gray-dark",
        className
      )}
    >
      <Typography type="button-sm" className="mr-[12px]">
        了解更多
      </Typography>
      <AiOutlineRight />
    </a>
  );
}

export default LearnMoreButton;
